import { useContext, useEffect } from 'react'
import { ErrorModalContext } from '../../context/errorModalContext'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { AxiosErrorData } from '../../types/api'
export const handleApiErros = (
  error: AxiosErrorData,
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  navigate: NavigateFunction,
) => {
  if (error?.response) {
    const statusCode = error.response.status
    if (statusCode >= 500) {
      setShowModal(true)
      setErrorMessage('Erreur du serveur')
    }
    if (statusCode === 403) {
      navigate('/forbidden')
    } else {
      setShowModal(true)
      setErrorMessage('Erreur du serveur')
      if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production') {
        console.log(error)
      }
    }
  }
}
const useHandleError = (error: any) => {
  const { setShowModal, setErrorMessage } = useContext(ErrorModalContext)
  const navigate = useNavigate()
  useEffect(() => {
    handleApiErros(error, setShowModal, setErrorMessage, navigate)
  }, [error])
}

export default useHandleError
