import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table'
import { useMemo, useState, useContext } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CustomTable from '../../../../components/common/customTable'
import SiteIcon from '../../../../icons/siteIcon'
import RemoteIcon from '../../../../icons/remoteIcon'
import OpenCloseArrowIcon from '../../../../icons/openCloseArrowIcon'
import {FormationType, FormationDetailsType, Schedule} from '../../../../types/api/formations'
import {  useGetNextFormationQuery, useGetNextFormationDetailsQuery, useGetNextFormationOtherDatesQuery } from '../../../../hooks/api/formations'
import {getDate} from '../../../../utils'
import ContainedButton from '../../../../components/common/buttons/containedButton'
import Loader from '../../../../components/common/loader'
import CustomModal from '../../../../components/common/modals/customModal'
import { useFormik } from 'formik'
import Label from '../../../../components/common/label'
import RadioButton from '../../../../components/common/fields/radioButton'
import CustomizedAccordions from '../../../../components/common/accordion'

import FormikContext from '../../../../context/formikContext'
import RadioGroup from '@mui/material/RadioGroup';
import BodyModalUpdateDates from './bodyModalUpdateDates';
import BodyModalErreur from './bodyModaErreur'



const DateTitle = () => <Box sx={{ pl: '15px' }}>Date</Box>
const DateBody = ({ row }: any) => (
  <Typography variant='smallTextNormal' pl='15px'>
    {getDate(row.original.start_date , row.original.end_date)}
  </Typography>
)
const TypeBody = ({ row }: any) => (
  <Box sx={{ ml: '8px' }}>{row.original.type === 'EC' ? <SiteIcon /> : <RemoteIcon />}</Box>
)
const TitleBody = ({ row }: any) => (
  <Typography variant='h6' component='a' href={row.original.moodle_link} target='_blank' sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
    {row.original.title}
  </Typography>
)

const NextFormations = () => {
  const [page, setPage] = useState('1')
  const [openCangeDate, setOpenChangeDate] = useState(false)
  const [dataId, setDatId] = useState<string>('')
  const [trainingId, setTrainingId] = useState<string>('')
  const [reqParam, setReqParam] = useState<object>({})
  const [value, setValue] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'start_date', desc: true }])
  const { data, isFetching, refetch: refreshData } = useGetNextFormationQuery(
    {
    page,
    sort: sorting[0],
  }
  )
  const { data : dataDetails, status, refetch  } = useGetNextFormationDetailsQuery(dataId??'', {enabled:!!dataId})
  const { data : formationDates, status: formationDatesStatus   } = useGetNextFormationOtherDatesQuery(trainingId??'', {enabled:!!trainingId})
 const handleOpenModal = () => {
  setTrainingId(dataId);
  setOpenChangeDate(true)
}  

const handleCloseModal = () => {
  setOpenChangeDate(false)
  // setSelectedModal(null)
}

const dataDates = [
{
  cohort: 'cohort',
  schedule: [
      {
          date: '02-09-2023',
          start_time: '08:00',
          end_time: '12:30'
      },
      {
          date: '02-09-2023',
          start_time: '13:00',
          end_time: '17:30'
      },
      {
          date: '03-09-2023',
          start_time: '08:00',
          end_time: '12:30'
      },
  ],
  id: '156',
  start_date: '01-09-2023',
  end_date: '08-09-2023',
  title: 'Santé et sécurité générale sur les chantiers de construction avec SIMDUT 2023 (En classe)'
},
{
  cohort: 'cohort',
  schedule: [
      {
          date: '02-09-2023',
          start_time: '08:00',
          end_time: '12:30'
      },
      {
          date: '02-09-2023',
          start_time: '13:00',
          end_time: '17:30'
      },
      {
          date: '03-09-2023',
          start_time: '08:00',
          end_time: '12:30'
      },
  ],
  id: '157',
  start_date: '01-09-2023',
  end_date: '08-09-2023',
  title: 'Santé et sécurité générale sur les chantiers de construction avec SIMDUT 2023 (En classe)'
},
]

  const columns = useMemo<MRT_ColumnDef<FormationType>[]>(
    () => [
      {
        header: 'Date',
        accessorKey: 'start_date',
        Header: DateTitle,
        maxSize: 20,
        minSize: 20,
        size: 20,
        enableSorting: true,
        Cell: DateBody,
      },
      {
        accessorKey: 'type',
        header: 'Type',
        maxSize: 8.5,
        minSize: 8.5,
        size: 8.5,
        enableSorting: true,
        Cell: TypeBody,
      },
      {
        accessorKey: 'title',
        header: 'Formation',
        size: 59,
        enableSorting: true,
        maxSize: 59,
        minSize: 59,
        Cell: TitleBody,
      },
    ],
    [],
  )
  const ExpandBody = ({ row, table }: any) => (
    <Box
      onClick={() => {
        if(!row.getIsExpanded()) {
          setDatId(row.original.training_id);
          table.resetExpanded()
        }
        row.toggleExpanded()

      }}
        sx={{
          mr: '12px',
          cursor: 'pointer',
          p: '5px',
          display: 'flex',
          alignItems: 'center',
          gap: '9px',
        }}
    >
      <Typography variant='smallTextNormal'>Voir l’horaire</Typography>
      <OpenCloseArrowIcon open={row.getIsExpanded()} />
    </Box>
  )
  const DetailPanel = ({ row }: any) =>  (

  <Box sx={{ display: 'flex', gap: '8%', alignItems: 'flex-start', width:'100%' }}>
    {status === 'loading' ? 
      <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        mt: '10px',
      }}
    >
      <Loader />
    </Box>  : 

(
  <>
      <Box width='20%'>
      <ContainedButton
              sx={{
                alignSelf: 'start',
                width: '190px',
                py: '12px',
                fontSize: '12px',
                lineHeight:'16px',
                minHeight:'auto'
              }}
              variant='principal'
              title='Changer de dates'
              handleClick={handleOpenModal}
            />
    </Box>
    <Box width='40%'>
      <Typography variant='h5' mb='10px' sx={{ letterSpacing: 'inherit' }}>
        Horaire 
      </Typography>
      <Typography component='ul' style={{listStyle:'none', paddingInlineStart: 0}} pl='26px' variant='smallTextNormal'>
        {dataDetails?.schedule?.map((item: null | Schedule) => (
          <Typography component='li' key={row.original?.crm_id}>{getDate(item?.date, null)} {item?.start_time} à {item?.end_time}</Typography>
        ))}
      </Typography>
    </Box>
    
    { row.original.type === 'EC' ?
        <Box width='20%'>
          <Typography variant='h5' mb='10px' sx={{ letterSpacing: 'inherit' }}>
            Adresse
          </Typography>
          <Typography variant='smallTextNormal'>{dataDetails?.address}</Typography>
      </Box> 
      : null
    }
    </>
)
  
  }


  </Box> 
) 

  return (
    <Box sx={{ 
      flex: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
      }}>
    <CustomTable
      muiTableBodyRowProps={({ row, isDetailPanel }) => ({
        sx: {
          ...(isDetailPanel &&
            !row.getIsExpanded() && {
              height: '0px',
              overflow: 'hidden',
            }),
        },
        hover: false,
      })}
      data={data?.data ?? []}
      columns={columns}
      enableSortingRemoval={false}
      enableSorting={true}
      enableMultiSort={false}
      manualSorting
      onSortingChange={(params) => {
        setPage('1')
        setSorting(params)
      }}
      state={{ sorting, isLoading: isFetching }}
      enableExpandAll={false}
      enableExpanding
      autoResetExpanded
      renderDetailPanel={DetailPanel}
      displayColumnDefOptions={{
        'mrt-row-expand': {
          size: 12.5,
          maxSize: 12.5,
          minSize: 12.5,
          header: '',
          muiTableBodyCellProps: ({ row }) => ({
            sx: {
              px: '0',
              justifyContent: 'end',
              borderBottom: '1px solid #DADCDD',
              ...(row.getIsExpanded() ? { borderBottom: 'none' } : {}),
            },
          }),
          Cell: ExpandBody,
        },
      }}
      muiTableDetailPanelProps={({ row, table }) => ({
        sx: {
          width: '100%',
          pt: '3px',
          display: 'table-cell',
          ...(row.getIsExpanded() ? { pb: '36px' } : {}),
        },
      })}
      propsPagination={{
        page: Number(page),
        count: data?.metadata?.last_page ? Number(data?.metadata?.last_page) : 0,
        onChange: (e, index) => {
          setPage(index.toString())
        },
      }}
    />
    <CustomModal open={openCangeDate} handleClose={handleCloseModal} sx={{width: '1000px'}}>
      {
          formationDatesStatus === 'loading' ? 
            <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              mt: '10px',
            }}
          >
            <Loader />
          </Box>  : 

          formationDates && formationDates?.length> 0 ?  
          <BodyModalUpdateDates 
          dataDates={formationDates} 
          status={formationDatesStatus} 
          handleClose={handleCloseModal}
          oldTrainingId = {trainingId}
          isRefreshed= {refetch}
          isRefreshedData= {refreshData}

          
          ></BodyModalUpdateDates>
        : 
        <BodyModalErreur handleClose={handleCloseModal} sx={{
          alignItems: 'center',
          '& .modal-header': {
            flexDirection:'column',
          },
          }}/>
      }
    </CustomModal>

  </Box>
  )
}

export default NextFormations
