import React from 'react'
import Typography from '@mui/material/Typography'
import { SxProps, Theme } from '@mui/material'
interface Props {
  href: string
  children: React.ReactNode
  sx?: SxProps<Theme>
}
const CustomExternalLink = ({ href, children, sx }: Props) => {
  return (
    <Typography
      component='a'
      sx={{ textDecoration: 'underline', fontWeight: '500', cursor: 'pointer', ...sx }}
      href={href}
      target='_blank'
    >
      {children}
    </Typography>
  )
}

export default CustomExternalLink
