import { withAuthenticationRequired } from '@auth0/auth0-react'
import React, { ComponentType } from 'react'
import GeneralLoader from './generalLoader'
import { useSearchParams } from 'react-router-dom'
import BlockedUserModal from '../common/modals/blockedUserModal'
import { t } from 'i18next'

interface AuthenticationGuardProps {
  component: ComponentType
}

export const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({ component }) => {
  const [queryParameters] = useSearchParams()
  const error = queryParameters.get('error')
  const error_description = queryParameters.get('error_description')

  if (error) {
    return (
      <BlockedUserModal
        open={true}
        errorDescription={error_description}
        errorTitle={t('common.connexionError')}
      />
    )
  }

  const Component = withAuthenticationRequired(component, {
    onRedirecting: GeneralLoader,
  })

  return <Component />
}
