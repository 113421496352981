import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ShoppingCartIcon from '../../../icons/shoppingCartIcon'

interface Props {
  content?:  null | string | number
}
const ShoppingCart = ({content}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '88px',
        color: 'secondary.main',
        backgroundColor: 'primary.main',
        height: '54px',
        my: '-15px',
      }}
    >
      <ShoppingCartIcon />
      <Typography variant='h6' sx={{ color: 'secondary.main', mb: '-7px', ml: '3px' }}>
        ({content ? content : 0})
      </Typography>
    </Box>
  )
}

export default ShoppingCart
