import React from 'react'
export type cartType = string | null | number

export interface CartContextType {
  cart: cartType
  setCart: (selectedItem: cartType) => void
}
export const CartContext = React.createContext<CartContextType>({
  cart: '' as cartType,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCart: () => {},
})
