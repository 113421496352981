import { useContext, useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { drawerWidth } from '..'
import RetunLinkAndActiveEnterperise from './retunLinkAndActiveEnterperise'
import DropDownMenu from './dropDownMenu'
import { ActiveRegionContext } from '../../../context/activeRegionContext'
import { CartContext } from '../../../context/cartContext'

import ShoppingCart from './shoppingCart'
const Header = () => {
  const { activeRegion } = useContext(ActiveRegionContext)
  const { cart } = useContext(CartContext)

  
  return (
    <AppBar
      position='fixed'
      sx={{
        backgroundColor: 'primary.lighter',
        boxShadow: 'none',
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          minHeight: 'auto!important',
          py: '15px',
          pl: '20px!important',
          pr: '0!important',
        }}
      >
        <RetunLinkAndActiveEnterperise />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant='h6'
            component='a'
            sx={{ mr: '34px', textDecoration: 'none' }}
            href={`${process.env.REACT_APP_JOIN_US}?regionid=${activeRegion}`}
          >
            Nous joindre
          </Typography>

          <DropDownMenu />
          <ShoppingCart content={cart} />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
