import React, { useState, useMemo, useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'
import Header from './header'
import NotifsMemberShip from './header/notifs'
import SideBar from './sideBar'
import ErrorModal from '../common/modals/errorModal'
import { ErrorModalContext } from '../../context/errorModalContext'
import { SidebarContext } from '../../context/sideBarContext'
import { CartContext, cartType } from '../../context/cartContext'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { ActiveEnterpriseContext } from '../../context/activeEnterpriseContext'
import { ActiveRegionContext } from '../../context/activeRegionContext'
import { getActiveEnterpriseFromLocalStorage, hasAccess } from '../../utils'
import Typography from '@mui/material/Typography'
import CookieConsent, { Cookies } from 'react-cookie-consent';

import { LocalStorageKeys, authorization } from '../../constants'
import GeneralLoader from './generalLoader'
import { useAuth0 } from '@auth0/auth0-react'
import styled from '@emotion/styled'
export const drawerWidth = 288

export default function Layout() {
  const [selectedItemSidebar, setSelectedItemSidebar] = useState('')
  const [activeEnterprise, setActiveEnterprise] = useState(getActiveEnterpriseFromLocalStorage())
  const [activeRegion, setActiveRegion] = useState(
    localStorage.getItem(LocalStorageKeys.ActiveRegion) ?? '',
  )
  const [cart, setCart] = useState<cartType>(
    localStorage.getItem(LocalStorageKeys.CartItem) ?? '',
  )
  const [showModal, setShowModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const location = useLocation()
  useEffect(() => {
    if (!localStorage.getItem(LocalStorageKeys.OpenItemsSideBar)) {
      localStorage.setItem(LocalStorageKeys.OpenItemsSideBar, JSON.stringify(['myProfile']))
    }
  }, [])
  const activeEnterpriseState = useMemo(
    () => ({ activeEnterprise, setActiveEnterprise }),
    [activeEnterprise, setActiveEnterprise],
  )
  const stateActiveRegion = useMemo(
    () => ({ activeRegion, setActiveRegion }),
    [activeRegion, setActiveRegion],
  )
  const stateCart = useMemo(
    () => ({ cart, setCart }),
    [cart, setCart],
  )
  const stateSelectedItemSidebar = useMemo(
    () => ({ selectedItemSidebar, setSelectedItemSidebar }),
    [selectedItemSidebar, setSelectedItemSidebar],
  )
  const stateErrorHandling = useMemo(
    () => ({ showModal, errorMessage, setShowModal, setErrorMessage }),
    [showModal, errorMessage, setShowModal, setErrorMessage],
  )
  const { isLoading } = useAuth0()

  if (isLoading) {
    return <GeneralLoader />
  }
  return (
    <ActiveEnterpriseContext.Provider value={activeEnterpriseState}>
      <ActiveRegionContext.Provider value={stateActiveRegion}>
        <SidebarContext.Provider value={stateSelectedItemSidebar}>
          <CartContext.Provider value={stateCart}>
            <ErrorModalContext.Provider value={stateErrorHandling}>
              <Box sx={{ display: 'flex' }}>
                <Header />
                <SideBar />
                <Box
                  component='main'
                  sx={{
                    flexGrow: 1,
                    paddingInline: '49px',
                    paddingTop: '124px',
                    paddingBottom: '100px',
                    minHeight: 'calc(100vh - 325px)'
                  }}
                >
                  <NotifsMemberShip/>
                  {!hasAccess(authorization[location.pathname]?.read || null, activeEnterprise) ? (
                    <Navigate to='/profile/personal-informations' replace />
                  ) : (
                    <>
                    <Outlet />
                    <CookieConsent
                    cookieName="utilisation"
                    buttonText="ok"
                    style={{ background: '#444d4ecc', color: '#fff', fontFamily: 'DIN',zIndex: '10000' }}
                    buttonStyle={{ background: '#f9a51a', color: '#fff', fontSize: '13px' }}
                    
                    >
                      Ce site Web utilise des cookies pour améliorer l'expérience utilisateur.
                      <>
                          <Typography
                            variant='h6'
                            component='a'
                            sx={{ ml: '20px',mr: '10px',color:'#fff' }}
                            href={`${process.env.REACT_APP_POLITIQUE_UTILISATION}`}
                          >
                            Politique d'utlisation
                          </Typography>
                          <Typography
                            variant='h6'
                            component='a'
                            sx={{ mr: '34px',color:'#fff' }}
                            href={`${process.env.REACT_APP_CONDITIONS_UTILISATION}`}
                          >
                            Conditions d'utlisation
                          </Typography>
                      </>
                      
                      </CookieConsent>
                    </>
                  )}
                </Box>
                <ErrorModal></ErrorModal>
              </Box>
            </ErrorModalContext.Provider>
          </CartContext.Provider>
        </SidebarContext.Provider>
      </ActiveRegionContext.Provider>
    </ActiveEnterpriseContext.Provider>
  )
}
